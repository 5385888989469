import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private appService: AppService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // this.appService.pendingDashBoardList().subscribe(
    //   (data) => {
    //   const value = localStorage.getItem('logskim_keySecret');
    //   if (value == undefined || value == null || value == 'null' || value == '') {
    //     //   this._router.navigate(['/admin-dashboard']);
    //     // }else{
    //     localStorage.clear()
    //     this._router.navigate(['/login']);
    //   }else{

    //   }
    // // }, (error) => {
    // //   localStorage.clear()
    // //   this._router.navigate(['/login']);

    // // });

    const value = localStorage.getItem('logskim_keySecret');
    const adminYn = localStorage.getItem('superAdminYn');
    const recruiterYn = localStorage.getItem('recruiterYn');
    if (value != undefined && value != null) {
      if (adminYn == 'Y') {
        this._router.navigate(['/admindashboard']);
      } else if (recruiterYn == 'Y') {
        this._router.navigate(['/recruiter-dashboard']);
      } else {
        this._router.navigate(['/dashboard']);
      }
    }
    return true;
  }
}
